<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- picD1CB9CD8F9413DDB9D5B77387C50586C.jpg -->
      <img style="height: 330px; width: 440px;" src="@/assets/news-network/all-good-things-come-in-threes-1.jpg" class="rounded">
      <br><br>
      On October 8, 2010 the LEGO® Universe team picked up 3 prestigious game awards at the
      Frankfurter Buchmesse in Germany!
      <br><br>
      The first two trophies of this shiny collection are the GIGA-Maus awards sponsored by
      the ELTERN FAMILY Magazine. With votes from educators, journalists, students and software
      industry experts, LEGO Universe got the perfect seal of quality, and won first place for
      both ‘‘Best online game’’ and ‘‘Overall Winner in the children’s category age +10’’.
      <br><br>
      Here’s what the GIGA-Maus jury had to say about the game:
      <br>
      ”Online games don’t necessarily have the best reputation among parents. However, that might
      well change after getting acquainted with LEGO Universe as there’s not alone tech support
      but a team of moderators working around the clock as well. Together kids can create a digital
      universe out of LEGO bricks; a fascinating way for the beloved brick to branch out into a
      virtual world!’’
      <br><br>
      The third prize to be waved around is our TOMMI award, decided by 3,000 eager kids who all
      played the game in 18 libraries all over the country. They gave LEGO Universe 3rd place for
      best online game and added: ‘‘Even though we are huge Lego fans, we have never seen an online
      game like this… LEGO Universe has a great story and it’s a well crafted online game with lots
      of different ways to play. We like all the cool quests, the missions, the action, but also
      building buildings or creating. LEGO is the best.’’
      <br><br>
      <!-- pic2EC357089AED844501862A9118FF2846.jpg -->
      <img style="height: 580px; width: 440px;" src="@/assets/news-network/all-good-things-come-in-threes-2.jpg" class="rounded">
<br></p>
















			</div>
</template>
